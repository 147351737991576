import AOS from 'aos';

$(document).ready(function() {
  AOS.init({
   duration: 1500
  });
  navBackground();

  $(window).scroll(function(){
    navBackground();
    if( window.visualViewport.width > 768 && window.visualViewport.width < 1200 ){
      var x = window.scrollY >= ((($('#main-content').height()/3)))
    }
    else if(window.visualViewport.width > 1920){
      var x = window.scrollY >= (($('#main-content').height()/3))
    }
    else{
      var x = window.scrollY >= (($('#main-content').height()/2))
    }
    if( x ){
      $('#main-video').css('position', 'absolute')
    }
    else {
      $('#main-video').css('position', 'fixed')
    }
    if( $(window).scrollTop() < 170){
      $('#text-1').removeClass('animate-fade');
    }
    else {
      $('#text-1').addClass('animate-fade');
    }
    if( $(window).scrollTop() > 170 && $(window).scrollTop() < 500) {
      $('#text-2').removeClass('animate-fade');
    }
    else {
      $('#text-2').addClass('animate-fade');
    }
    if( $(window).scrollTop() > 500){
      $('#text-3').removeClass('animate-fade');
    }
    else {
      $('#text-3').addClass('animate-fade');
    }
  })

  function navBackground() {
    var header = document.getElementById("header-nav");
    var navbar = document.getElementById("nav");
    var sticky = header.offsetHeight;
    if (window.pageYOffset > sticky ) {
      navbar.classList.add("shadow");
      navbar.classList.remove("py-md-2");
    }
    else{
      navbar.classList.remove("shadow");
      navbar.classList.add("py-md-2");
    }
  }

});
